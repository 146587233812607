import { fetchUtils } from 'ra-core';
import { UserIdentity } from 'react-admin';

class AuthProvider {
  constructor(private httpClient = fetchUtils.fetchJson) {}

  async login(form: unknown) {
    throw new Error('implementation error. authProvider.login should not be called.');
  }

  async checkError({ body }: { message: string; status: number; body: unknown }) {
    if (typeof body === 'object' && body !== null && 'code' in body) {
      const { code } = body as { code: unknown };
      if (typeof code === 'string' && code.startsWith('auth/')) {
        console.error('auth error:', code);
        return Promise.reject();
      }
    }
    return Promise.resolve();
  }

  async checkAuth(): Promise<void> {
    await this.getIdentity();
  }

  async logout(): Promise<void> {
    await this.httpClient('/auth/api/session/logout', { method: 'POST' });
  }

  async getIdentity(): Promise<UserIdentity> {
    const resp = await this.httpClient('/admin-api/v1/adminUsers/me');
    return { id: resp.json.data.id, fullName: resp.json.data.name };
  }

  async getPermissions(): Promise<string[]> {
    return [];
  }
}

const authProvider = new AuthProvider();

export default authProvider;
